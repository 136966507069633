import type { ReactiveHead } from '@unhead/vue';
import {
  useHead,
} from '@unhead/vue';
import { storeToRefs } from 'pinia';
import {
  computed,
  toRef,
} from 'vue';

import { DeviceMeta } from '@leon-hub/environment-client';
import { getUserAgent } from '@leon-hub/service-locator-env';

import { useI18nStore } from '@core/i18n';
import { useRootStore } from '@core/root';
import { useSiteConfigStore } from '@core/site-config';
import { useTheme } from '@core/theme';

import {
  useHtmlClassList,
  useLayoutInfo,
} from 'web/src/modules/core/composables';
import useAppFullscreenStore from 'web/src/modules/core/store/useAppFullscreenStore';
import { useAppClassList } from 'web/src/modules/core/utils';
import useMetaTheme from 'web/src/modules/seo/components/useMetaTheme';
import useSeoMetaProperties from 'web/src/modules/seo/composables/useSeoMetaProperties';

export default function useMainAppMetaInfo(): void {
  const { locale } = storeToRefs(useI18nStore());
  const rootStore = useRootStore();
  const isAppMainContentLoaded = toRef(rootStore, 'isAppMainContentLoaded');
  const isContentSelectionDisabled = toRef(rootStore, 'isContentSelectionDisabled');
  const isAppInFullscreenMode = toRef(useAppFullscreenStore(), 'isAppInFullscreenMode');
  const { theme } = useTheme();
  const {
    metaTitle,
    metaTags,
    metaScripts,
  } = useSeoMetaProperties();
  const {
    isLanding,
    isFullWidth,
  } = useLayoutInfo();

  const { themeColor } = useMetaTheme();
  const fontFamily = toRef(useSiteConfigStore(), 'fontFamily');

  const classList = computed<string[]>(() => {
    // TODO: manage these all classes via store
    const result: string[] = useAppClassList(theme.value, isAppInFullscreenMode.value, fontFamily.value);

    if (DeviceMeta.isTablet(getUserAgent())) {
      result.push('app__layout--tablet');
    }

    if (isAppMainContentLoaded.value) {
      result.push('app__content-ready');
    }

    if (isContentSelectionDisabled.value) {
      result.push('user-select-none');
    }

    if (isFullWidth.value) {
      result.push('app__content--full-width');
    }

    if (isLanding.value) {
      result.push('app__content--landing');
    }

    return result;
  });

  const initialHeadClassName = process.env.VUE_APP_RENDERING_CSR ? document.documentElement.className : '';
  const metaSource = computed<ReactiveHead>(() => ({
    title: metaTitle?.value || '',
    htmlAttrs: {
      lang: locale.value.split('_')[0].toLowerCase(),
      class: initialHeadClassName,
    },
    meta: [
      {
        charset: 'utf-8',
      },
      {
        name: 'format-detection',
        content: 'telephone=no',
      },
      {
        name: 'theme-color',
        content: themeColor.value,
      },
      {
        name: 'viewport',
        content: [
          'width=device-width',
          'initial-scale=1',
          'maximum-scale=1',
          'user-scalable=no',
          'viewport-fit=cover',
        ].join(', '),
      },
      ...(metaTags?.value || []),
    ],
    script: Array.from(metaScripts?.value || []),
  }));

  useHead(metaSource);
  useHtmlClassList(classList);
}
