import type { Ref } from 'vue';
import {
  computed,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import { useTheme, useThemeColorStore, useThemeStore } from '@core/theme';

import type { ModalHeaderType } from '@components/dialogs';

export interface MetaThemeComposable {
  themeColor: Ref<string | undefined>;
}
export default function useMetaTheme(): MetaThemeComposable {
  const { isLight } = useTheme();
  const router = useRouter();
  const isSkeletonColor = computed(() => !!router.currentRoute.value.meta.hasTopBar);
  const modalHeaderType = computed<ModalHeaderType | undefined>(() => router.currentRoute.value.meta.topBarType);
  const { setCustomStatusbarColor } = useThemeStore();
  const customThemeColor = toRef(useThemeColorStore(), 'customThemeColor');

  const skeletonColor = computed<string | undefined>(() => (
    isLight.value ? process.env.VUE_APP_THEME_COLOR_LIGHT_MODAL : process.env.VUE_APP_THEME_COLOR_DARK_MODAL
  ));

  const headerColor = computed<string | undefined>(() => (
    isLight.value ? process.env.VUE_APP_THEME_COLOR_LIGHT_DEFAULT : process.env.VUE_APP_THEME_COLOR_DARK_DEFAULT
  ));

  const modalHeaderColor = computed(() => {
    if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      return undefined;
    }

    const headerType = customThemeColor.value ?? modalHeaderType.value;

    switch (headerType) {
      case 'default':
        return 'rgba(240, 242, 245, 1)';
      case 'secondary':
        return 'rgba(86,0,245,1)';
      case 'tertiary':
        return 'rgba(255,255,255,1)';
      default:
        return undefined;
    }
  });

  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && process.env.VUE_APP_PLATFORM_CORDOVA) {
    watch(modalHeaderType, (newValue) => {
      setCustomStatusbarColor(newValue === 'secondary');
    }, { immediate: true });
  }

  const themeColor = computed<string | undefined>(() => {
    if (modalHeaderColor.value) {
      return modalHeaderColor.value;
    }

    return isSkeletonColor.value ? skeletonColor.value : headerColor.value;
  });

  return {
    themeColor,
  };
}
